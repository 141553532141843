import { MRT } from 'modartt-web/src/js/__modartt_backoffice.js'

const _BO = MRT.bo;
const _UI = MRT.ui;

const Admin = {
	version: "1.7.5",
	servicePath: 'api/0',
	jsPath: 'assets/js',
	classPrefix: 'admin',
	moduleSuffix: 'mrta',
	modules: [],
	modals: {},
	cache: {a:1},
	elements: []
};

Admin.Element = class extends _BO.Element {
	constructor(type, element){
		super(type, element);
		element.classList.add(Admin.classPrefix);
		Admin.elements.push(this);
	}
}

/**
 * init is executed only if module is really loaded, else use then
 * @todo should use _BO.loadModule
 */
Admin.loadModule = async (module_name, init)=>{
	if(Admin.modules.indexOf(module_name)>=0) return false;
	try {

		let url = Admin.jsPath+'/admin.'+module_name+'.js';
		if(module_name.match(new RegExp('\.'+Admin.moduleSuffix+'$'))){
			url = Admin.jsPath+'/'+module_name+'.js';
		}

		await MRT.getScript(url);
	}
	catch(evt){
		let e = new Error(evt.message || 'Unable to load Admin module "'+module_name+'"');
		e.module = module_name;
		e.event = evt;
		throw e;
	}
	Admin.modules.push(module_name);
	if(init instanceof Function) return init();
}

Admin.service = async (service, _params, _options)=>{
	const params = Object(_params);
	const options = Object(_options);

	if(!options.url) options.url = Admin.servicePath;

	try {
		const data = await _BO.service(service, params, options);

		if(Admin.NoteWidget && (options.noteControl instanceof Admin.NoteWidget)){
			MRT.debug('note', data.note.id, data.note.html);
			if(data.note) options.noteControl.appendNote(data.note.id, data.note.html);
		}

		return data;
	}
	catch(err){
		const str_err = String(err);
		const askForLogin = (Admin.login instanceof Function) && (str_err.match(/session required/i) || err.sessionRequired);

		if(askForLogin){
			_UI && Admin.closeAllModals();
			const modal = await Admin.login({title:'The session is expired, please log in again', type:'warning', page:MRT.info('page')});
			return {asked:'login', modal:modal};
		}
		throw err;
	}
};

Admin.download = async (service, params, filename, _options)=>{

	const options = Object(_options);
	if(filename==='!') filename=null;
	else if(!filename) options.catchResponse = function(res){
		const header = res.headers.get('Content-Disposition') || '';
		const m = header.match(/filename=(.+)/i);
		if(m) filename = m[1].trim();
	}

	const raw = await Admin.service(service, params, options);
	const blob = (raw instanceof Blob) ? raw : new Blob([JSON.stringify(raw)],{type:'application/json'});

	const url = Admin.window.URL.createObjectURL(blob);

	const download = ()=>{
		const link = document.createElement('a');
		link.href = url;
		link.download = filename;
		link.click();
	}

	if(filename){ //force immediate download
		download()
	}

	if(options.onsuccess instanceof Function){
		const obj = {
			url : url,
			getBlob: ()=>{ return blob; }
		}
		if(filename){
			obj.download = download;
			obj.getFile = ()=>{
				return new File([blob], filename);
			}
		}

		return options.onsuccess(obj);
	}

	return url;
};

Admin.login = async (_options)=>{
	const options = Object(_options);
	const form_selector = 'form.login-box';
	if(Admin.login.attempt) throw new Error('Login box already loaded');

	if(!Admin.modals.login){
		let data = await Admin.service('session', {action:'login_box'}).catch((err)=>{
			Admin.login.attempt = true;
			MRT.message(err);
			throw err;
		});
		let modal = Admin.modals.login = new _UI.Modal({id:'login-modal', backdrop:'static', keyboard:false});
		//if(options.title) modal.title(options.title, options.type);
		modal.content(data.html);
		modal.get('body').classList.add('text-center');
		let footer = modal.get('footer');
		if(footer) footer.remove();

		let form = modal.element.querySelector(form_selector);

		let inputs = Array.from(form.elements).filter((e)=>{ return e.autocomplete!==undefined });
		inputs.forEach((e)=>{ e.autocomplete = false; });

		form.onsubmit = function(){
			const form = this;
			const fdata = new FormData(this);
			fdata.append('action', 'connect');
			Admin.service('session', fdata).then(()=>{
				inputs.forEach((e)=>{ e.autocomplete = true; });
				if(options.onsuccess instanceof Function) options.onsuccess.call(Admin.modals.login, form, options);
				else form.submit();
			},(err)=>{
				modal.message(err.message || err);
				MRT.debug('Login error', err);
				if(options.onfail instanceof Function) options.onfail.call(Admin.modals.login, form, options);
			})
			return false;
		};
	}

	const form = Admin.modals.login.element.querySelector(form_selector);
	if(options.page){
		form.action = Admin.getURL(options.page, {from_login:1});
	}
	else if(options.action) form.action = options.action;

	Admin.modals.login.options(options);
	Admin.modals.login.open();
	return Admin.modals.login;

};

Admin.logout = async ()=>{
	await Admin.service('session', {logout:1, action:'logout'});

	const modal = await Admin.login({title:'Your session is closed', type:'success', page:MRT.info('page')})

	const successButton = modal.element.querySelector('.btn-success');
	if(successButton){
		successButton.insertAdjacentHTML('beforebegin','<a class="btn btn-primary" style="color:white" href="?page=home"><i class="fa fa-arrow-circle-left"></i> Back to the log in screen</a>');
	}

	return modal;
};

Admin.notify = async (params, options)=>{

	if (!("Notification" in window)){
		throw new Error('This browser does not support desktop notification');
	}

	var go = ()=>{
		options = Object(options);
		return Admin.service(options.service || 'notification', params, options.service).then((data)=>{
			var N = new Notification(data.title || data.message, {
				lang: 'en',
				tag: 'admin',
				body: data.title?data.message:undefined,
				icon: 'images/favicon-admin.png',
				badge: 'images/favicon-admin.png',
				requireInteraction: options.confirm
			});
			if(options.onclick instanceof Function) N.onclick = options.onclick;
			if(options.onerror instanceof Function) N.onerror = options.onerror;
			return data;
		});
	};

	if (Notification.permission === "granted") return go();
	else if (Notification.permission === "default"){
		Notification.requestPermission().then((permission)=>{
			if (permission === "granted") return go();
			else throw new Error('Permission denied');
		});
	}
};

Admin.checkVAT = async (vat, _options)=>{
	const options = Object(_options);
	const data = await Admin.service('vat',{check:1, vat: vat, strict: options.strict!==undefined?options.strict:true})

	const result = data.result;
	MRT.dialog(['Country: '+result.countryCode, 'Number: '+result.vatNumber, 'Date: '+result.requestDate, 'Company: '+result.name, 'Address: '+result.address].join('<br/>'), 'success', {title:'VAT number is valid'});
}

Admin.help = async (what, showModal)=>{
	const data = await Admin.service('misc', {help:what});

	if(showModal){
		let dtitle = 'Help';
		if(!Admin.modals.help){
			let m = new _UI.Modal({id:'admin-modal-help', title:dtitle});
			m.get('dialog').classList.add('modal-lg');
			Admin.modals.help = m;
		}
		Admin.modals.help.title('<i class="fa fa-circle-info"></i> '+(data.title || dtitle), data.type || 'info');
		Admin.modals.help.content(data.html || data.text);
		Admin.modals.help.open();
	}
	return data.html || data.text;
}

Admin.countryFlags = (selector)=>{
	if(typeof(selector) == 'string') selector = document.body.querySelectorAll(selector);
	return Array.from(selector).map((e)=>{
		const iso = e.textContent.toUpperCase().substr(0,2);
		if(iso.match(/[A-Z]{2}/)){
			const icon = e.querySelector('span.flag-icon');
			if(icon) icon.remove();
			e.insertAdjacentHTML('afterbegin','<span class="flag-icon '+iso+' mx-1"></span>');
			return e.firstElementChild;
		}
		return null;
	}).filter(e => e!=null);
};

Admin.handleElements = (elements, _options)=>{
	const options = Object(_options);
	if(elements instanceof HTMLElement) elements = [elements];


	const list = Array.from(elements).map((e)=>{

		if(e.dataset.adminTooltip){
			e.dataset.bsToggle = 'tooltip';
			e.dataset.bsTitle = e.dataset.adminTooltip;
			e.dataset.bsTrigger = 'hover';
			e.dataset.bsDelay = 500;
			e.dataset.bsHtml = 'true';
			e.removeAttribute('data-admin-tooltip');
			delete(e.dataset.adminTooltip);
			return null;
		}

		const admin_toggle = (e.dataset.adminToggle||'').trim().toLowerCase();
		if(!admin_toggle) return null;
		if(e._admin) throw new Error('Admin element already handled');
		switch(admin_toggle){
			case 'input-search':
				if(Admin.Search) return Admin.Search.manageInputSearch(e);
				break;
			case 'info':
				if(Admin.Search) return Admin.Search.makeSearchable(e, options);
				break;
			case 'toggle':
			case 'copy':
				e.dataset.boValue = e.dataset.adminValue;
			case 'clipboard':
			case 'tooltip':
				e.dataset.boToggle=admin_toggle;
				delete(e.dataset.adminToggle);
				break;
		}

		return null;
	}).filter((o)=>{ return o!=null; });


	const bo_list = _BO.handleElements(elements, _options);

	list.push(...bo_list);

	return list;
}

Admin.selectGroups = async ()=>{
	const data = await Admin.service('session',{groups:1});

	if(data.groups.length==1){
		MRT.message('You belong to one group only', 'warning');
		return;
	}

	if(!Admin.modals.selectGroups){
		let modal = Admin.modals.selectGroups = new _UI.Modal({id:'groups-modal'});
		modal.title('<i class="fa fa-users"></i> Active administration groups');

		var html = '';
		data.groups.forEach(function(e){
			html += '<div class="my-1"><label class="clickable"><input type="checkbox" name="group" value="'+e+'"/> '+e+'</label></div>';
		})
		modal.content(html);

		const boxes = modal.get('body').querySelectorAll('input[type=checkbox]');

		boxes.forEach((e)=>{
			e.checked = data.selected.indexOf(e.value)>=0;
		})

		modal.get('footer').insertAdjacentHTML('beforeend', '<button class="btn btn-success">Set</button>');

		const btn = modal.get('footer').lastElementChild;

		btn.onclick = function(){
			const groups = Array.from(boxes).filter(e=>e.checked);
			if(groups.length==0) return modal.message('You must select one group at least');
			Admin.service('session', {active_groups:groups.map(e=>e.value)}).then(()=>{
				Admin.window.history.go(0);
			},(err)=>{
				modal.message(err);
			})
		};
	}

	Admin.modals.selectGroups.open();
	return Admin.modals.selectGroups;
}

Admin.changePassword = function(){

	if(!Admin.modals.changePassword){
		const modal = Admin.modals.changePassword = new _UI.Modal({id:'password-modal'});
		modal.title('<i class="fa fa-key"></i> Change password');
		modal.content('<div class="text-left m-2"><label><span style="display:inline-block;width:8em">New password:</span></label>\
			<input type="password" minlength="8" name="password" class="my-1" style="width:16em"/> <button><i class="fa fa-eye-slash"></i></button></div>\
			<div class="text-left m-2"><label><span style="display:inline-block;width:8em">Re-type it:</span></label>\
			<input type="password" minlength="8" name="verif" class="my-1" style="width:16em"/> <button><i class="fa fa-eye-slash"></i></button></div>');

		const btn = _BO.modal.appendTo(modal, '<button class="btn btn-success">Change</button>');

		const body = modal.get('body');

		btn.onclick = function(){
			var pwd = body.querySelector('input[name=password]');
			var verif = body.querySelector('input[name=verif]');
			if(pwd.value!=verif.value) return modal.message('Passwords are different');

			if(!pwd.validity.valid) return modal.message(pwd.validationMessage);
			if(!verif.validity.valid) return modal.message(verif.validationMessage);

			Admin.service('session', {change_password:pwd.value}).then((data)=>{
				MRT.message(data.success || data.message,'success');
				modal.close();
			}, (err)=>{
				modal.message(err);
			});
		};

		const eye_toggle = function(){
			this.firstChild.classList.toggle('fa-eye');
			this.firstChild.classList.toggle('fa-eye-slash');
			this.previousElementSibling.type = this.firstChild.classList.contains('fa-eye')?'text':'password';
		}

		modal.get('body').querySelectorAll('button').forEach((e)=>{
			e.addEventListener('click', eye_toggle);
		})

	} else {
		Admin.modals.changePassword.message(null);
		Admin.modals.changePassword.get('body').querySelectorAll('input').forEach((e)=>{
			e.value = '';
		});
	}
	Admin.modals.changePassword.open();
	return Admin.modals.changePassword;
}

Admin.getURL = (page, query)=>{
	const p = new _BO.Page(page);
	return p.getURL(query);
}

Admin.goPage = (page, query)=>{
	document.location.search = Admin.getURL(page, query);
};

Admin.openLogModal = async (params)=>{

	const load = async (modal, params)=>{
		const get_refresh_icon = ()=>{ return modal.get('footer').querySelector('button .fa-rotate') };

		get_refresh_icon().classList.add('fa-spin');

		const data = params.data || await Admin.service('logs', params);

		modal.content(Admin.displayLog(data));

		modal.get('body').querySelectorAll('.mrt-number, .number').forEach((e)=>{
			e.classList.add('clickable');
			e.onclick = function(){
				const number = e.textContent;
				const text = e.parentElement.querySelector('.mrt-line, .line').textContent;
				MRT.copyTextToClipboard(text).then(()=>{
					modal.message('Row #'+number+' copied to clipboard', 'success');
				});
			}
		})

		const title = 'Log file: <strong>'+data.directory+'/'+data.file+'</strong> tail:'+(params.tail||data.tail)+' found:'+MRT.length(data.lines);

		modal.title(title);
		MRT.delay(500).then(()=>{
			get_refresh_icon().classList.remove('fa-spin');
		});

		return data;
	}

	if(!Admin.modals.log){
		let modal = Admin.modals.log = new _UI.Modal({id:'log-modal'});

		const footer = modal.get('footer');

		footer.insertAdjacentHTML('afterbegin', 'Filter: <input type="text" style="width:30em" class="admin-filter-text"/> <button class="btn admin-refresh"><i class="fa fa-rotate"></i></button> <button class="btn admin-copy"><i class="fa fa-clipboard"></i></button> </button> <button class="btn admin-full"><i class="fa fa-expand"></i></button>');

		const cpy_btn = footer.querySelector('.admin-copy');
		cpy_btn.onclick = function(){
			const buf = [];
			modal.get('body').querySelectorAll('.log-table .line').forEach((e)=>{
				buf.push(e.textContent);
			})
			const promise = MRT.copyTextToClipboard(buf.join('\n')).then(()=>{
				return {success:"Log content copied to clipboard"};
			});
			modal.message(promise);
			return false;
		};

		footer.querySelector('.admin-full').onclick = function(){
			const dialog = modal.element.querySelector('.modal-dialog');
			const bool = dialog.classList.toggle('modal-fullscreen');
			dialog.style['max-width'] = bool?'inherit':'80%';
			dialog.querySelector('.mrt-log-table').style['max-height'] = bool?'inherit':'80vh';
		}

		modal.get('body').onmouseup = function(){
			footer.querySelector('input.admin-filter-text').value = Admin.window.getSelection().toString().trim();
		}
	}

	const modal = Admin.modals.log;
	const filterInput = modal.get('footer').querySelector('input.admin-filter-text');
	const refresh_btn = modal.get('footer').querySelector('.admin-refresh');

	refresh_btn.classList.toggle('d-none', !params.file);
	filterInput.classList.toggle('d-none', !params.file);

	refresh_btn.onclick = function(){
		const _params = Object.assign({}, params);
		if(_params.data) delete(_params.data);

		const filterValue = filterInput.value.trim();
		if(filterValue) _params.filter = filterValue;

		load(modal, _params);

		return false;
	}

	filterInput.onkeydown = function(event){
		if(event.code === "Enter"){
			refresh_btn.click();
			return false;
		}
	}

	params.action = 'load';

	await load(modal, params);

	modal.open();

	return true;
};

Admin.displayLog = (data, container)=>{
	const html_buf = []
	html_buf.push('<div class="mrt-log-table log-table mrt-log-scrollable">');
	MRT.forEach(data.lines,(l,n)=>{
		html_buf.push('\t<div><span class="mrt-number number">'+(n+1)+'</span><span class="mrt-line line">'+l.replaceAll('<','&lt;').replaceAll('>','&gt;')+'</span></div>');
	});
	html_buf.push('</div>');
	const html = html_buf.join('\n');

	if(container instanceof HTMLElement){
		container.innerHTML = html;
	}
	return html;
};

Admin.openSuccessModal = (body, title)=>{
	return _BO.modal.showReturn(body, title || 'Success', 'success').onclose();
}

Admin.openFailModal = (body, title)=>{
	return _BO.modal.showReturn(body, title || 'Fail', 'danger').onclose();
}

Admin.closeAllModals = (onlyAdminRegistered)=>{
	if(onlyAdminRegistered) return Promise.all(Object.values(Admin.modals || {}).map(m=>m.close()));
	return _UI.Modal.closeAll();
}

Admin.showMixedResult = (data, type)=>{
	if(type=='text'){
		return _UI.Modal.dialog('<pre>'+data+'</pre>', 'info', {title:'Text data'});
	}
	return _UI.Modal.JSON(data);
}

Admin.alert = async (m, t, o)=>{
	await MRT.alert(m, t, o).catch(MRT.void);
}

Admin.softConfirm = async (message, options)=>{
	const o = Object(options);
	if(o.pass) return true;
	return await _UI.Modal.confirm(message || 'Do you confirm?', o.type || 'info', o).then(()=>{ return true; }, ()=>{ return false; });
}

Admin.bind = (action, parentNode, handler, eventFunc)=>{
	const elts = [];
	parentNode.querySelectorAll('[data-admin-toggle').forEach((e)=>{
		if(e.dataset.adminToggle != action) return;
		e[eventFunc || 'onclick'] = function(event){
			event.preventDefault();
			handler.call(e, event, action, parentNode);
			return false;
		}
		elts.push(e);
	})
	return elts;
}

Admin.message = (m, t, o)=>{
	return MRT.message(m, t, o);
}

Admin.initialize = async (_window)=>{
	if(Admin.initialized) return;

	const _w = _window || window
	await _BO.initialize(_w);
	Admin.window = _w;
	const servicePath = MRT.info('service_path');
	if(servicePath) Admin.servicePath = servicePath;

	// Tabs
	const tab_list = Admin.Tabs = [].slice.call(document.querySelectorAll('.nav-tabs.admin-control .nav-link'))
	tab_list.forEach((e)=>{
		const tab = _UI.bootstrapElement(e, 'tab');
		e.addEventListener('click', function (event) {
			event.preventDefault()
			tab.show();
		});
		if(e.classList.contains('active')){
			const selector = e.dataset.bsTarget;
			const element = document.querySelector(selector);
			if(element) element.classList.add('show', 'active');
			//console.debug('Element', element, selector)
			tab.show();
		}
	})

	Admin.loadTabs = Array.from(document.body.querySelectorAll('.nav .nav-link[data-admin-load]')).map((e)=>{
		const link = e.dataset.adminLoad;
		e.onclick = function(){
			document.location = link;
		};
		return e;
	});

	document.body.querySelectorAll('select.admin-auto').forEach((e)=>{
		new _UI.Combo(e);
	})

	// Forms
	document.body.querySelectorAll('input.price').forEach((e)=>{
		e.onchange = function(){
			this.value = Math.round(parseFloat(this.value.replace(',','.'))*100)/100;
		};
	});

	document.body.querySelectorAll('input[type=submit], button[type=submit]').forEach((e)=>{
		e.onclick = function(){
			if(!e.form) return;
			Array.from(e.form.elements).forEach((e)=>{
				if(e.classList.contains('search')){
					const select = e._adminSelect;
					if(!select) return;
					e.value = select.value;
				}
			})
		};
	})

	// Clipboard
	Array.from(document.body.querySelectorAll('.copyable, .mrt-copyable')).forEach((e)=>{
		new _BO.Copyable(e);
	})

	// Messages quick reset
	const main_messages = document.getElementById('messages');
	if(main_messages){
		const r = main_messages;
		r.addEventListener('click', (event)=>{
			if(event.shiftKey){
				event.stopPropagation();
				r.innerHTML = '';
				r.dispatchEvent(new CustomEvent('clear'));
				return false;
			}
		})

		r.querySelectorAll('.message').forEach((e)=>{
			e.onclick = ()=>{ MRT.ui.vanish(e); r.dispatchEvent(new CustomEvent('change')); };
		})
	}

	const interactive_elements = document.body.querySelectorAll('[data-admin-tooltip], [data-admin-toggle], [data-bs-toggle]');
	Admin.elements = Admin.handleElements(interactive_elements);

	document.body.querySelectorAll('.btn-vat-check').forEach((e)=>{
		if(!(e.form instanceof HTMLFormElement)) return;
		if(!(e.form.vat instanceof HTMLInputElement)) return;
		e.onclick = function(){
			const vat = e.form.vat.value.trim();
			const promise = Admin.checkVAT(vat);
			MRT.loading(promise, 'Checking VAT number '+vat);
			MRT.message(promise);
			return false;
		};
	});

	const note_panels = document.querySelectorAll('.admin-notes');

	document.body.querySelectorAll('a.nav-link').forEach((e)=>{
		e.addEventListener('click', function(){
			if(e.href.match(/(page|action)=logout/) || e.id=="navbar_logout" || e.id=="navbar_quit"){
				Admin.logout();
				return false;
			}
		});
	});

	document.body.querySelectorAll('.admin-modal').forEach((e)=>{
		const modal = _UI.Modal.wrap(e);
		const id = (e.id || e.name || e.dataset.adminId || e.dataset.adminName);
		if(id) Admin.modals[id] = modal;
	});

	document.body.querySelectorAll('.btn[data-admin-confirm], .btn[data-confirm]').forEach((e)=>{
		e.onclick = function(){
			const link = e.dataset.adminConfirm;
			MRT.confirm().then(()=>{
				document.location = link;
			});
		}
	});

	MRT.lib.Admin = Admin.version;
	Admin.initialized = true;

	// Admin modules
	{
		let onError = (err)=>{
			MRT.message('Module loading error: '+err);
		}

		Admin.loadModule('search', ()=>{ Admin.Search.init() }).catch(onError);

		if(!Admin.NoteWidget && note_panels.length){
			Admin.loadModule('notes').catch(onError);
		}

		if(document.querySelectorAll('.admin-api').length){
			Admin.loadModule('api').catch(onError);
		}

		if(document.querySelectorAll('table.result').length){
			Admin.loadModule('table', ()=>{ Admin.Table.init() }).catch(onError);
		}
	}

	return true;
};

MRT.admin = Admin;

export { MRT }
import { MRT } from './admin.js'

const Admin = MRT.admin;

Admin.isReady = Admin.initialize(window).then((data)=>{
	if(MRT.Debug) MRT.Debug.isReady.then(()=>{
		MRT.Debug.logDependencies();
	});
	return data;
});

window.MRT = MRT;
window.Admin = Admin;


